import React, { useState } from "react";
import playicon from "../../images/icons/play_circle_filled.svg";

import Popup from "reactjs-popup";

function Videocontcard(props) {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  return (
    <div>
      <div className="videocard d-flex flex-column align-items-center" onClick={() => setOpen(o => !o)}>
        <div className="w-100 position-relative">
          <img className="w-100 img-fluid mb-2" src={props.videothumb} alt="video" />
          <img className="position-absolute playiconpos img-fluid rounded" src={playicon} alt="" />
        </div>
        <div className="videotext">
          <span className="videotext1">#{props.numberis} {props.ownername}</span>
          <span className="videotext2">: {props.videoname}</span>
        </div>
      </div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modalopen">
          <a className="modalclose" onClick={closeModal}>
            &times;
          </a>
          <iframe className="w-100 h-100 videoplayc" src={props.videodis} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Popup>
    </div>
  );
}

export default Videocontcard;
