import React, { useState } from "react";
import playicon from "../../images/icons/play_circle_filled.svg";

import Popup from "reactjs-popup";
import { Script } from "gatsby"

function Twitcontcard(props) {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [loaded, setLoaded] = useState(false)
  return (
    <div>
      <div className="videocard d-flex flex-column align-items-center" onClick={() => setOpen(o => !o)}>
        <div className="w-100 position-relative">
          <img className="w-100 img-fluid mb-2" src={props.videothumb} alt="video" />
          <img className="position-absolute playiconpos img-fluid rounded" src={playicon} alt="" />
        </div>
        <div className="videotext">
          <span className="videotext1">#{props.numberis} {props.ownername}</span>
          <span className="videotext2">: {props.videoname}</span>
        </div>
      </div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modalopen">
          <a className="modalclose" onClick={closeModal}>
            &times;
          </a>
          <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Today Mason will be talking to Punter from blockblend about life and success. <br/><br/>Win $200 by asking the most thoughtful question on HedgeTalk. To ask question, fill this form:<a href="https://t.co/t8IEDOfzE2">https://t.co/t8IEDOfzE2</a><a href="https://t.co/jNhfTe6lAi">https://t.co/jNhfTe6lAi</a></p>&mdash; HedgePay (@HedgePay_) <a href="https://twitter.com/HedgePay_/status/1568563211821273088?ref_src=twsrc%5Etfw">September 10, 2022</a></blockquote>
          <Script src="https://platform.twitter.com/widgets.js" onLoad={() => setLoaded(true)} />
          {loaded && <Script src="https://platform.twitter.com/widgets.js" />}

        </div>
      </Popup>
    </div>
  );
}

export default Twitcontcard;
