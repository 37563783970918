import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Popup from "reactjs-popup";

import interview1 from "../images/interview1.jpg";
import Linkedin from "../images/icons/Linkedin.svg";
import telegram from "../images/icons/telegram.svg";
import twitterGold from "../images/icons/twitterGold.svg";
import www from "../images/icons/www.svg";
import phstarfill from "../images/icons/ph_star-fill.svg";
import search from "../images/icons/search.svg";
import bigplayButton from "../images/icons/Button.png";

import firsthpaythumb from "../images/video/firsthpaythumb.jpg";
import secondhpaythumb from "../images/video/secondhpaythumb.jpg";
import fourthHpayThumb from "../images/video/fourthHpayThumb.jpg";
import firthHpayThumb from "../images/video/firthHpayThumb.jpg";
import twittercard from "../images/video/twittercard.jpg";

import Amaladdercard from "../components/hedgetalks/Amaladdercard";
import Amaladdertwittercard from "../components/hedgetalks/Amaladdertwittercard";
import Videocontcard from "../components/hedgetalks/Videocontcard";
import Twitcontcard from "../components/hedgetalks/Twitcontcard";
import Paginationtalk from "../components/hedgetalks/Paginationtalk";

const Hedgetalk = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  return (
    <Layout>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="HedgeTalk" />
      <Container className="py-5">
        <Row className="justify-content-start">
          <Col lg={9} className="mb-5">
            <h3 className="left-title">HedgeTalk</h3>
            <div className="goldline"></div>

            <div>
              <p>
                Join HedgeTalk for our interactive podcast, where we open up the floor to world-class leaders in
                business, marketing, blockchain, and software services.This is your chance to hear people share how they
                achieved their success and an opportunity for you to ask your own questions. The most thoughtful
                question, decided by the host, will win a cash prize. Terms and conditions apply. Join us and let your
                voice be heard.
              </p>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex flex-row-reverse talkbtn">
              <button className="hedgetalkPribtn">Sign Up</button>
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col lg={12}>
            <h3 className="left-title mb-5 pt-5">Trending</h3>
            <div className="w-100 border-gradient d-flex flex-md-row flex-column">
              <div className="leftslide position-relative videotrendfea" onClick={() => setOpen(o => !o)}>
                <img
                  className="w-100 h-100 position-absolute img-fluid videoadscover"
                  src={interview1}
                  alt="interview with Ai-tech"
                />
                <img className="position-absolute playiconpos img-fluid rounded" src={bigplayButton} alt="" />
              </div>
              <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className="modalopen">
                  <a className="modalclose" onClick={closeModal}>
                    &times;
                  </a>
                  <iframe
                    className="w-100 h-100 videoplayc"
                    src="https://www.youtube.com/embed/a2hs4GSb77E?autoplay=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </Popup>
              <div className="px-4 py-4 px-sm-5 py-sm-5 rightslidetrend">
                <div className="mb-3">
                  <h3 className="SlideHeading">
                    <span className="worldtilte">#1 Paul</span> : Ai-Tech
                  </h3>
                </div>
                <div className="mb-2 d-flex flex-md-row flex-column ">
                  <div className="d-flex flex-row align-items-center mb-1">
                    <a
                      href="https://www.linkedin.com/company/solidus-ai-tech-ltd"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="iconname"
                    >
                      <img src={Linkedin} alt="" />
                    </a>
                    <a href="https://t.me/defiyield_app" target="_blank" rel="noopener noreferrer" className="iconname">
                      <img src={telegram} alt="" />
                    </a>
                    <a
                      href="https://twitter.com/defiyield_app"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="iconname"
                    >
                      <img src={twitterGold} alt="" />
                    </a>
                    <a href="https://defiyield.app/" target="_blank" rel="noopener noreferrer" className="iconname">
                      <img src={www} alt="" />
                    </a>
                  </div>
                  <div className="voteworldclass d-flex justify-content-center align-items-center position-relative">
                    <img className="pr-1" src={phstarfill} alt="" />
                    <span className="votetext">3130 Votes</span>
                  </div>
                </div>
                <p className="mb-3 innerpara">
                  Paul is a born achiever &#38; demands the highest standards in everything he puts his name behind. He
                  is a passionate entrepreneur and established himself as a successful investor very early on in his
                  career. His main focus was on Property &#38; Fine Art until he was introduced to Bitcoin in 2015. This
                  led Paul to the successful venture with AiTech.
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <div className="my-5 slidecard">
          <div className="slidercontainer d-flex flex-row">
            <Videocontcard
              numberis="2"
              ownername="Matt"
              videoname="True DeFi"
              videothumb={secondhpaythumb}
              videodis="https://www.youtube.com/embed/-mmU5-d7Y7U?autoplay=1"
            ></Videocontcard>
            <Videocontcard
              numberis="3"
              ownername="Kyle"
              videoname="Bet To Earn"
              videothumb={firsthpaythumb}
              videodis="https://www.youtube.com/embed/JAPbfDxpboY?autoplay=1"
            ></Videocontcard>
            <Videocontcard
              numberis="4"
              ownername="Barret"
              videoname="$SPEEZY"
              videothumb={firthHpayThumb}
              videodis="https://www.youtube.com/embed/sJwmI8FGPJs?autoplay=1"
            ></Videocontcard>
            <Twitcontcard numberis="5" ownername="Punter" videoname="Blockblend" videothumb={twittercard} />
          </div>
        </div>
        <Row>
          <Col>
            <h3 className="left-title mb-5 pt-5">Leaders Onboard</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="d-flex justify-content-center justify-content-md-start mb-4">
            <form className="searchbox" action="">
              <div className="">
                <div className="input-group w-100">
                  <input
                    type="text"
                    className="hedgetalkformcontrol"
                    id="specificSizeInputGroupUsername"
                    placeholder="Enter Your Keyword"
                  ></input>
                  <div className="input-group-text searchbtn d-flex justify-content-center align-items-center">
                    <img src={search} alt="" />
                  </div>
                </div>
              </div>
            </form>
          </Col>
          <Col lg={6} className="d-flex justify-content-start justify-content-md-end ">
            <Paginationtalk></Paginationtalk>
          </Col>
        </Row>
        <Row className="blogpostspace">
          <Amaladdercard
            thumb={fourthHpayThumb}
            goldtitle="Ai-Tech"
            title="Worlds First Artificial Intelligence utility token"
            votecount="3130"
            duration="60:01"
            date="12 Mar 2022"
            time="17:00"
            videodis="https://www.youtube.com/embed/Vfph_kEcLC4?autoplay=1"
          ></Amaladdercard>
          <Amaladdercard
            thumb={secondhpaythumb}
            goldtitle="True DeFi"
            title="The only standalone Swapper in BSC"
            votecount="1230"
            duration="47:14"
            date="13 Mar 2022"
            time="14:00"
            videodis="https://www.youtube.com/embed/-mmU5-d7Y7U?autoplay=1"
          ></Amaladdercard>
          <Amaladdercard
            thumb={firsthpaythumb}
            goldtitle="Bet To Earn"
            title="Guess the Winner and be rewarded"
            votecount="12"
            duration="37:00"
            date="12 Mar 2022"
            time="14:00"
            videodis="https://www.youtube.com/embed/JAPbfDxpboY?autoplay=1"
          ></Amaladdercard>
        </Row>
        <Row className=" d-flex justify-content-center">
          <Amaladdercard
            thumb={firthHpayThumb}
            goldtitle="Speakeasy"
            title="Spin Jackpot and a P2P Sports Betting Dapp"
            votecount="1300"
            duration="60:03"
            date="14 Feb 2022"
            time="17:00"
            videodis="https://www.youtube.com/embed/sJwmI8FGPJs?autoplay=1"
          ></Amaladdercard>
          <Amaladdertwittercard
            thumb={twittercard}
            goldtitle="Blockblend"
            title="The only standalone Swapper in BSC"
            votecount="1230"
            duration="66:50"
            date="10 Sept 2022"
            time="16:00"
          />
        </Row>
        <Row className="finalbtntalk">
          <Col className="d-flex justify-content-center align-items-center">
            <div className="d-flex flex-row-reverse">
              <button className="hedgetalkPribtn">Sign Up</button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Paginationtalk></Paginationtalk>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Hedgetalk;
